import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from '@core/services/log/log.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  /**
   * @param _logService
   */
  constructor(private _logService: LogService) {
    super();
  }

  /**
   * @param {Error} error
   */
  override handleError(error: Error) {
    this._logService.logException(error);
  }
}
