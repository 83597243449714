import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookiebotConfig } from '@config/cookiebot.config';
import { CoreModule } from '@core/core.module';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service.factory';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { MockInterceptor } from '@core/interceptors/mock.interceptor';
import { CookieConsentService } from '@core/services/cookies/cookie-consent.service';
import { ErrorHandlerService } from '@core/services/log/error-handler.service';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';
import { PublicModule } from '@modules/public/public.module';
import { SharedModule } from '@shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { apiConfigurationProvider, requestBundleConfig } from '@config/request-bundle.config';
import {
  ffNgxFormErrorProvider,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  FfNgxStorageService,
} from '@fagforbundet/ngx-components';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { controlErrorMessages } from '@config/control-errors/control-error.config';
import { initializeInMemoryStoreFactory } from '@config/in-memory-store-initializer.factory';

@NgModule({
  declarations: [AppComponent],
  exports: [AngularSvgIconModule, RouterModule],
  bootstrap: [AppComponent], imports: [AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    PublicModule,
    SharedModule], providers: [
    apiConfigurationProvider(),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    ngxCookiebotProvider(CookiebotConfig),
    CookieConsentService,
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentServiceFactory,
      deps: [CookieConsentService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeInMemoryStoreFactory,
      deps: [FfNgxStorageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb',
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
