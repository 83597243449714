import { Component, Inject, LOCALE_ID } from '@angular/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import localeNbExtra from '@angular/common/locales/extra/nb';

registerLocaleData(localeNb, 'nb', localeNbExtra);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  /**
   * @param _document
   * @param _locale
   */
  constructor(@Inject(DOCUMENT) private _document: Document, @Inject(LOCALE_ID) private _locale: string) {
    this._document.documentElement.lang = _locale;
  }
}
