import { CookieConsentService } from '@core/services/cookies/cookie-consent.service';

/**
 *
 * @param cookieConsentService
 */
export function cookieConsentServiceFactory(cookieConsentService: CookieConsentService) {
  return (): Promise<any> => {
    return cookieConsentService.init();
  };
}
