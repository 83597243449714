import { Injectable } from '@angular/core';
import { LogService } from '@core/services/log/log.service';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(private readonly _cookieBotService: NgxCookiebotService, private readonly _logService: LogService) {}

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this._cookieBotService.onServiceReady$
        .pipe(
          filter((ready: boolean) => {
            return ready;
          }),
          tap(() => {
            this._setUpEventHandlers();
          }),
        )
        .subscribe();

      return resolve();
    });
  }

  private _setUpEventHandlers(): void {
    this._cookieBotService.onConsentReady$
      .pipe(
        tap(() => {
          this._handleUserConsent();
        }),
      )
      .subscribe();
  }

  private _handleUserConsent(): void {
    if (this._cookieBotService.cookiebot.consent.statistics) {
      this._logService.logConsented = true;
      void this._logService.init();
    }
  }
}
