import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // When not logged in, API will return a 401 status code combined w/ a 'NO_ACCESS' error code.
          // Landing page (route '/') uses 'LoggedInGuard' that checks if you're logged in and redirects you
          //   to profile page.
          // If you're not logged in (401 from /self endpoint), and on route '/'
          //   then we avoid redirecting as it would cause endless loop
          if (401 === err.status && err.error.error === 'NO_ACCESS' && this._router.url !== '/') {
            void this._router.navigate(['error'], {
              queryParams: { error_type: ErrorPageErrorsEnum.SESSION_EXPIRED },
            });
          }
        }

        return throwError(() => err);
      }),
    );
  }
}
