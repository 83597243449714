import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable()
export class PostfixPageTitleStrategy extends TitleStrategy {
  constructor(private readonly _titleService: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this._titleService.setTitle(`${title} - Fagforbundet ID`);
    }
  }
}
