import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';

/**
 * If useMocks is sat to true in the environment file, this interceptor will
 * fetch local json files with the same data structure you would get from the actual API.
 *
 * Mock data folder / file structure must follow API paths:
 * API path: GET :baseUrl/v1/users/self
 * Local path: assets/mocks/api/v1/users/self.get.json
 */
@Injectable({
  providedIn: 'root',
})
export class MockInterceptor implements HttpInterceptor {
  constructor(private _http: HttpClient) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (EnvironmentHelper.useMocks() && EnvironmentHelper.isAPIRequest(req.url)) {
      const httpRequest = new HttpRequest('GET', EnvironmentHelper.replaceWithMockUrl(req.url, req.method));

      req = Object.assign(req, httpRequest);
    }

    return next.handle(req);
  }
}
